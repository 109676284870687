<script lang="ts">
  import Icon from '$lib/components/icon/icon.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { MiniCard } from '$lib/contentful/models/mini-card';
  import { iconVariants } from '$lib/contentful/models/theme';
  import Button from '../button/button.svelte';
  import RichText from '../rich-text/rich-text.svelte';

  type $$Props = {
    content: MiniCard;
  };

  export let content: MiniCard;
  $: ({
    icon,
    title,
    description,
    callsToAction,
    entityId,
    theme = 'ultraviolet',
  } = content);
</script>

<div class="card {theme}">
  <div class="card-content">
    <div class="flex items-center sm:items-start sm:flex-col gap-6">
      {#if icon !== null}
        <Icon
          variant={iconVariants[theme]}
          width={40}
          height={40}
          name={icon}
        />
      {/if}
      <Text fieldId="title" {entityId} class="text-white" variant="heading-24"
        >{title}</Text
      >
    </div>
    <RichText
      text={description}
      fieldId="description"
      theme="dark"
      {entityId}
    />
    {#each callsToAction as { text, ...rest }}
      <Button {...rest} {theme}>{text}</Button>
    {/each}
  </div>
</div>

<style lang="postcss">
  .card {
    @apply h-auto sm:min-h-[9.25rem] w-full rounded-2xl bg-space-black border;

    &.off-white {
      @apply border-graphite;
    }

    &.ultraviolet {
      @apply border-transparent border-gradient-ultraviolet;
    }

    &.green {
      @apply border-transparent border-gradient-green;
    }

    &.pink-gradient {
      @apply border-transparent border-gradient-pink;
    }

    &.mist {
      @apply border-transparent border-gradient-mist;
    }

    &.pink {
      @apply border-bubble-gum;
    }
  }

  .card-content {
    @apply rounded-b-2xl flex flex-col gap-6 p-6;
  }
</style>
