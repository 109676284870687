<script lang="ts">
  import hljs from 'highlight.js/lib/core';
  import typescript from 'highlight.js/lib/languages/typescript';
  import go from 'highlight.js/lib/languages/go';
  import python from 'highlight.js/lib/languages/python';
  import php from 'highlight.js/lib/languages/php';
  import java from 'highlight.js/lib/languages/java';

  import {
    LANGUAGES,
    type CodeBlock,
    type Language,
  } from '$lib/contentful/models/code-block';
  import Button from '../button/button.svelte';

  hljs.registerLanguage('typescript', typescript);
  hljs.registerLanguage('go', go);
  hljs.registerLanguage('python', python);
  hljs.registerLanguage('php', php);
  hljs.registerLanguage('java', java);

  export let content: CodeBlock;

  const defaultLanguage = (): Language => {
    for (const language of LANGUAGES) {
      if (content.samples[language] !== undefined) return language;
    }
    return 'TypeScript';
  };

  let language: Language = defaultLanguage();
  let codeElement: HTMLElement;

  $: code = content.samples[language];
  $: {
    if (codeElement) {
      hljs.highlightElement(codeElement);
    }
  }

  const handleLanguageChange = (lng: Language) => {
    language = lng;
  };
</script>

<div class="code-block">
  <div class="language-tab-list" role="tablist" aria-label="Language Tabs">
    {#each LANGUAGES as lang}
      {#if content.samples[lang] !== undefined}
        <button
          on:click={() => handleLanguageChange(lang)}
          aria-selected={language === lang}
          class:active={language === lang}
          data-contentful-entry-id={content.entityId}
          data-contentful-field-id={lang.toLowerCase()}
          class="language-tab"
          role="tab">{lang}</button
        >
      {/if}
    {/each}
  </div>
  {#key [language, code]}
    <pre
      class="overflow-auto h-[320px] mt-5"
      data-contentful-entry-id={content.entityId}
      data-contentful-field-id={language.toLowerCase()}><code
        bind:this={codeElement}
        class="hljs language-{language}">{code}</code
      ></pre>
  {/key}
  <div class="flex flex-row justify-end">
    <Button
      leadingIcon="book"
      variant="ghost"
      theme="ultraviolet"
      href="https://docs.temporal.io/dev-guide/{language}"
    >
      Documentation
    </Button>
  </div>
</div>

<style lang="postcss">
  .code-block {
    @apply bg-blue-gray-900 rounded-lg p-10;
  }

  .language-tab-list {
    @apply flex flex-row items-start gap-6 overflow-auto;
  }

  .language-tab {
    @apply border-0 cursor-pointer appearance-none font-sans max-sm:text-lg sm:text-2xl bg-blue-gray-900 text-blue-gray-500;

    &.active {
      @apply text-white;

      &::after {
        @apply block content-[''] w-full h-[2px] rounded-full bg-gradient-ultraviolet;
      }
    }
  }

  .hljs {
    @apply font-mono sm:text-lg font-medium leading-[140%] text-off-white bg-blue-gray-900;

    :global(.hljs-keyword) {
      @apply text-[#C4B5FD];
    }

    :global(.hljs-string) {
      @apply text-[#F9A8D4];
    }

    :global(.hljs-title.function_) {
      @apply text-[#93C5FD];
    }
  }
</style>
