<script lang="ts">
  import Text from '$lib/components/text/text.svelte';
  import Icon from '../icon/icon.svelte';
  import type { Testimonial } from '$lib/contentful/models/testimonial';
  import RichText from '../rich-text/rich-text.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import { twMerge } from 'tailwind-merge';

  export let content: Testimonial;
  $: ({ quote, source, sourceAvatar, company, companyLogo, title, entityId } =
    content);

  let className: string | undefined = undefined;
  export { className as class };
</script>

{#if content}
  <div class={twMerge('testimonial', className)}>
    <div
      class="quote"
      data-contentful-field-id="heading"
      data-contentful-entry-id={entityId}
    >
      <Icon name="quote" class="text-ultraviolet" height="32" width="32" />
      <RichText theme="light" text={quote} fieldId="quote" {entityId} />
    </div>
    <div class="source-content">
      <div class="source-wrapper">
        {#if sourceAvatar}
          <ContentfulImage
            class="flex rounded-full w-14 h-14"
            src={sourceAvatar.url}
            alt="{source}, {title} at {company}"
            {entityId}
            fieldId={'sourceAvatar'}
            width={104}
          />
        {/if}
        <div class="source-attributions">
          <Text
            class="text-space-black"
            variant="eyebrow-18"
            {entityId}
            fieldId="source"
          >
            {source}
          </Text>
          {#if title && company}
            <Text
              class="text-blue-gray-700/70"
              variant="body-16"
              {entityId}
              fieldId="company"
            >
              {title}, {company}
            </Text>
          {/if}
        </div>
      </div>
      {#if companyLogo}
        <ContentfulImage
          class="flex h-auto w-28"
          src={companyLogo.url}
          alt={company}
          data-contentful-entry-id={entityId}
          data-contentful-field-id={'companyLogo'}
          width={112}
        />
      {/if}
    </div>
  </div>
{/if}

<style lang="postcss">
  .testimonial {
    @apply box-content p-8 flex flex-col justify-between gap-4 md:gap-12 rounded-3xl shadow-thick bg-white max-md:max-w-md md:max-w-2xl m-2;
  }

  .quote {
    @apply flex flex-col text-left gap-4;
  }

  .source-wrapper {
    @apply flex flex-row items-center gap-2;
  }

  .source-attributions {
    @apply flex flex-col gap-1 text-left;
  }

  .source-content {
    @apply flex items-center max-sm:flex-col gap-4 max-sm:items-start sm:flex-row justify-between;
  }
</style>
