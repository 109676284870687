import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { TypeCodeBlock } from '../generated-types';

export const LANGUAGES = ['TypeScript', 'Go', 'Python', 'PHP', 'Java'] as const;
export type Language = (typeof LANGUAGES)[number];

export type CodeBlock = {
  title: string | undefined;
  samples: Record<Language, string | undefined>;
  entityId?: string;
};

export const toCodeBlock = (
  codeblock: TypeCodeBlock<'WITHOUT_UNRESOLVABLE_LINKS', never> | undefined,
): CodeBlock | undefined => {
  if (codeblock === undefined) return;
  return {
    title: codeblock.fields.title,
    samples: {
      Go: codeblock.fields.go
        ? documentToPlainTextString(codeblock.fields.go)
        : undefined,
      Python: codeblock.fields.python
        ? documentToPlainTextString(codeblock.fields.python)
        : undefined,
      PHP: codeblock.fields.php
        ? documentToPlainTextString(codeblock.fields.php)
        : undefined,
      Java: codeblock.fields.java
        ? documentToPlainTextString(codeblock.fields.java)
        : undefined,
      TypeScript: codeblock.fields.typescript
        ? documentToPlainTextString(codeblock.fields.typescript)
        : undefined,
    },
    entityId: codeblock.sys.id,
  };
};
