<script lang="ts">
  import type { CodeSnippet } from '$lib/contentful/models/code-snippet';
  import CopyButton from '../copy-button/copy-button.svelte';

  type $$Props = {
    slim?: boolean;
    dark?: boolean;
    content: CodeSnippet;
    class?: string;
  };

  export let content: CodeSnippet;
  export let slim: boolean = false;
  export let dark: boolean = false;
  const { code, copyable } = content;
</script>

<div
  class="code-snippet"
  class:slim
  class:dark
  data-contentful-entry-id={content.entityId}
  data-contentful-field-id={'code'}
>
  <code class="code">
    {code}
  </code>

  {#if copyable}
    <CopyButton content={code} label="Copy code sample" />
  {/if}
</div>

<style lang="postcss">
  .code-snippet {
    @apply rounded-lg flex flex-row items-start justify-between gap-4 p-10 bg-blue-gray-900;

    &.slim {
      @apply p-4;
    }
  }

  .dark {
    @apply bg-space-black;
  }

  .code {
    @apply whitespace-pre-wrap text-white font-mono text-xl max-h-fit;
  }
</style>
